import React from "react"
import { Card, Link } from "@hdir/ui"
import { faArrowRight } from "@fortawesome/pro-light-svg-icons"
import FontAwesomeIconWrapper from "../../../react4xp/shared/FontAwesomeIconWrapper/FontAwesomeIconWrapper"

import './banner.scss'
import { bannerItemType } from './type.d'

function Banner(props: Readonly<bannerItemType>) {

    const { itemTitle, itemText, itemImage, imageAltText, imageWidth, forwardLink, imageAlignment, openLinkInNewTab } = props
    const imageAlignmentIndex = ["top", "left", "right"].indexOf(imageAlignment)

    const imageAlignmentClasses = ["flex-col", "!flex-row mobile:!flex-col", "!flex-row-reverse mobile:!flex-col-reverse"]
    const imageHeightClass = ["h-[420px]", "h-[250px]", "h-[250px]"]
    const imageFitClass = (imageWidth == 'full') ? "object-cover w-full" : "object-contain block m-auto mobile:object-cover"
    const cardPaddingClasses = ["p-md", "p-lg", "p-lg"]
    const textBlockClasses = ["pt-md", "pl-md flex-1 flex-col mobile:pt-md", "pr-md flex-1 mobile:pb-md"]
    const subTitleClasses = ["", "", "mb-lg"]
    const arrowAlignment = ["", "", "absolute mobile:relative"]

    return (
      <div className="w-full flex py-lg tablet:py-md mobile:py-sm">
        { forwardLink ? (
          <Link variant="card"
            className={`banner-card rounded-sm my-lg shadow-none !w-full !m-0 !p-md mobile:p-sm flex ${cardPaddingClasses[imageAlignmentIndex]} ${imageAlignmentClasses[imageAlignmentIndex]}`}
            href={forwardLink} target={openLinkInNewTab ? "_blank" : ""}>
            <Card.Banner position="top">
              <img
                className={`${imageHeightClass[imageAlignmentIndex]} ${imageFitClass} mobile:h-[300px] rounded-sm overflow-hidden`}
                src={itemImage}
                alt={imageAltText} />
            </Card.Banner>

            <div className={`h-full break-normal hyphens-auto w-full flex justify-between ${textBlockClasses[imageAlignmentIndex]} mobile:px-0`}>
              <div>
                {itemTitle &&
                  <h2 className="pb-md">{itemTitle}</h2>}
                {itemText &&
                  <p className={`font-normal text-md flex items-end ${subTitleClasses[imageAlignmentIndex]}`}>{itemText}</p>}
              </div>
              <FontAwesomeIconWrapper icon={faArrowRight} style={{ color: "var(--design-color-icon-action-default)" }} size="lg"
                  className={`stroke-action h-5 w-5 pointer-events-none self-end ${arrowAlignment[imageAlignmentIndex]}`} />
            </div>
          </Link>
          ) : (
          <Card
            className={`banner-card rounded-sm my-lg shadow-none !w-full !m-0 !p-md mobile:p-sm flex ${cardPaddingClasses[imageAlignmentIndex]} ${imageAlignmentClasses[imageAlignmentIndex]}`}>
            <Card.Banner position="top">
              <img
                className={`${imageHeightClass[imageAlignmentIndex]} ${imageFitClass} mobile:h-[300px] rounded-sm overflow-hidden`}
                src={itemImage}
                alt={imageAltText} />
            </Card.Banner>

            <div className={`h-full break-normal hyphens-auto w-full flex justify-between ${textBlockClasses[imageAlignmentIndex]} mobile:px-0`}>
              <div>
                {itemTitle &&
                  <h2 className="pb-md">{itemTitle}</h2>}
                {itemText &&
                  <p className={`font-normal text-md flex items-end ${subTitleClasses[imageAlignmentIndex]}`}>{itemText}</p>}
              </div>
            </div>
          </Card>
          )}
      </div>
    )
}

export default (props: Readonly<bannerItemType>) => <Banner {...props} />